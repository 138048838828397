import React, { useState, useEffect } from 'react'
import { auth } from '../../firebase'
import { GetUser, DeleteUser } from '../../service/api'
import Layout from '../../components/Layout'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { DataGrid } from '@mui/x-data-grid'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { styled } from '@mui/material/styles'
import useStore from '../../store'
import Notification from '../../components/Notification'
import useTokenID from '../../hooks/use-token-id'
const DeleteButton = styled(Button)(() => ({
  width: 20,
  color: 'red',
  '&.MuiButtonBase-root': {
    fontSize: '12px',
    padding: '2px 15px',
    border: '1px solid red',
    minWidth: 'max-content',
    textTransform: 'none',
  },
}))

export default function User() {
  const tokenID = useTokenID()
  const notificationOnSuccess = useStore(state => state.notificationState)
  const setNotificationOnSuccess = useStore(state => state.handleNotification)
  const [alert, setAlert] = useState({ severity: 'success', message: 'hello' })
  const [selectionModel, setSelectionModel] = useState([])
  const [data, setData] = useState([])
  const [open, setOpen] = useState(false)
  const [deleteID, setDeleteID] = useState(0)
  const [confirm, setConfirm] = useState(false)
  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'email', headerName: 'Email', width: 300 },
    { field: 'username', headerName: 'Username', width: 300 },
    { field: 'password', headerName: 'Password', width: 300 },
    { field: 'address', headerName: 'Address', width: 300 },
    { field: 'is_verified', headerName: 'Is Verified', width: 300 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 130,
      sortable: false,
      renderCell: params => {
        return (
          <DeleteButton
            onClick={() => handleClickOpen(params)}
            variant="outlined"
            size="small"
          >
            Delete
          </DeleteButton>
        )
      },
    },
  ]
  const handleClickOpen = params => {
    setOpen(true)
    setDeleteID(params.id)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleConfirmation = () => {
    setConfirm(true)
    setOpen(false)
  }

  const getUsers = async tokenID => {
    const { data } = await GetUser(tokenID)
    if (data) {
      console.log(data)
      setData(data)
    } else {
      throw new Error('Problem fetching user data')
    }
  }

  const deleteUser = async (tokenID, deleteID) => {
    const { data } = await DeleteUser(deleteID, tokenID)
    console.log(data)
    if (data.status === 1) {
      setConfirm(false)
      getUsers(tokenID)
      setAlert({ severity: 'success', message: 'User deleted successfully' })
      setNotificationOnSuccess(true)
    } else {
      setConfirm(false)
      getUsers(tokenID)
      setAlert({ severity: 'error', message: 'Fail to delete User' })
      setNotificationOnSuccess(true)
    }
  }

  const processRowUpdate = newRow => {
    return
  }

  useEffect(() => {
    if (tokenID !== '' && auth.currentUser !== null) {
      getUsers(tokenID)
    }
  }, [tokenID])

  useEffect(() => {
    if (confirm && tokenID !== 0) {
      deleteUser(tokenID, deleteID)
    }
  }, [confirm, deleteID, tokenID])

  useEffect(() => {
    document.title = 'User - Dashboard'
  }, [])

  useEffect(() => {
    if (notificationOnSuccess) {
      setTimeout(() => {
        setNotificationOnSuccess(false)
      }, 2000)
    }
  }, [notificationOnSuccess])

  return (
    <Layout>
      <Notification colour={alert.severity} message={alert.message} />
      <Box
        sx={{
          width: '70%',
          padding: 2,
          flexGrow: 1,
          overflow: 'scroll',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <span
            style={{
              fontSize: '18px',
              textTransform: 'uppercase',
              fontWeight: 500,
              marginRight: '10px',
            }}
          >
            User
          </span>
        </Box>
        <div style={{ height: '95%', width: '100%', marginTop: '20px' }}>
          <DataGrid
            rowHeight={35}
            rows={data}
            columns={columns}
            pageSize={50}
            rowsPerPageOptions={[50]}
            onSelectionModelChange={newSelectionModel => {
              setSelectionModel(newSelectionModel)
            }}
            processRowUpdate={processRowUpdate}
            selectionModel={selectionModel}
            disableMultipleSelection={true}
            experimentalFeatures={{ newEditingApi: true }}
            disableColumnMenu={true}
          />
        </div>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Delete User?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this User?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleConfirmation} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  )
}
