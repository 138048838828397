import React, { useState, useEffect } from 'react'
import { auth } from '../../firebase'
import {
  AddBlockchain,
  DeleteBlockchain,
  GetBlockchainList,
  UpdateBlockchain,
} from '../../service/api'
import Layout from '../../components/Layout'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { DataGrid } from '@mui/x-data-grid'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { styled } from '@mui/material/styles'
import useStore from '../../store'
import Notification from '../../components/Notification'
import useTokenID from '../../hooks/use-token-id'

const AddButton = styled(Button)(() => ({
  width: 20,
  color: '#15C0FC',
  '&.MuiButtonBase-root': {
    fontSize: '12px',
    padding: '2px 15px',
    border: '1px solid #15C0FC',
    minWidth: 'max-content',
    textTransform: 'none',
  },
}))
const DeleteButton = styled(Button)(() => ({
  width: 20,
  color: 'red',
  '&.MuiButtonBase-root': {
    fontSize: '12px',
    padding: '2px 15px',
    border: '1px solid red',
    minWidth: 'max-content',
    textTransform: 'none',
  },
}))

export default function Blockchain() {
  const tokenID = useTokenID()
  const notificationOnSuccess = useStore(state => state.notificationState)
  const setNotificationOnSuccess = useStore(state => state.handleNotification)
  const [alert, setAlert] = useState({ severity: 'success', message: 'hello' })
  const [selectionModel, setSelectionModel] = useState([])
  const [data, setData] = useState([])
  const [open, setOpen] = useState(false)
  const [openBlockchainForm, setOpenBlockchainForm] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [deleteID, setDeleteID] = useState(0)
  const [deleteName, setDeleteName] = useState('')
  const [blockchainName, setBlockchainName] = useState('')
  const [blockchainDisplayName, setBlockchainDisplayName] = useState('')
  const [save, setSave] = useState(false)
  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    {
      field: 'name',
      headerName: 'Name',
      width: 400,
      editable: true,
    },
    {
      field: 'display_name',
      headerName: 'Display Name',
      width: 400,
      editable: true,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 130,
      sortable: false,
      renderCell: params => {
        return (
          <DeleteButton
            onClick={() => handleClickOpen(params)}
            variant="outlined"
            size="small"
          >
            Delete
          </DeleteButton>
        )
      },
    },
  ]
  const handleClickBlockchain = () => {
    setOpenBlockchainForm(true)
  }
  const handleCloseBlockchain = () => {
    setOpenBlockchainForm(false)
  }
  const handleClickOpen = params => {
    setDeleteName(params.row.display_name)
    setOpen(true)
    setDeleteID(params.id)
  }

  const handleClose = () => {
    setOpen(false)
  }
  const handleConfirmation = () => {
    setConfirm(true)
    setOpen(false)
  }
  const handleAddBlockchain = () => {
    setSave(true)
  }
  const processRowUpdate = newRow => {
    const updatedRow = { ...newRow, isNew: false }
    updateBlockchain(newRow, newRow.id, tokenID)
    return updatedRow
  }

  const getBlockchainList = async tokenID => {
    const { data } = await GetBlockchainList(tokenID)
    if (data) {
      setData(data.data)
    } else {
      throw new Error('Problem fetching data')
    }
  }

  const addBlockchain = async (
    blockchainName,
    blockchainDisplayName,
    tokenID,
  ) => {
    const { data } = await AddBlockchain(
      blockchainName,
      blockchainDisplayName,
      tokenID,
    )
    if (data.status === 1) {
      setSave(false)
      setOpenBlockchainForm(false)
      getBlockchainList(tokenID)
      setAlert({ severity: 'success', message: 'Blockchain added successfully' })
      setNotificationOnSuccess(true)
    }
    else{
      setSave(false)
      setOpenBlockchainForm(false)
      getBlockchainList(tokenID)
      setAlert({ severity: 'error', message: 'Blockchain fail to add' })
      setNotificationOnSuccess(true)
    }
  }

  const updateBlockchain = async (newBlockchain, blockchainID, tokenID) => {
    const { data } = await UpdateBlockchain(
      newBlockchain,
      blockchainID,
      tokenID,
    )
    if (data.status === 1) {
      getBlockchainList(tokenID)
      setAlert({ severity: 'success', message: 'Blockchain updated successfully' })
      setNotificationOnSuccess(true)
    }
    else {
      getBlockchainList(tokenID)
      setAlert({ severity: 'error', message: 'Blockchain fail to update' })
      setNotificationOnSuccess(true)
    }
  }

  const deleteBlockchain = async (id, tokenID) => {
    const { data } = await DeleteBlockchain(id, tokenID)
    if (data.status === 1) {
      setConfirm(false)
      getBlockchainList(tokenID)
      setAlert({ severity: 'success', message: 'Blockchain deleted successfully' })
      setNotificationOnSuccess(true)
    }
    else {
      setConfirm(false)
      getBlockchainList(tokenID)
      setAlert({ severity: 'error', message: 'Blockchain fail to delete' })
      setNotificationOnSuccess(true)
    }
  }

  useEffect(() => {
    if (
      tokenID !== '' &&
      save &&
      blockchainName !== '' &&
      blockchainDisplayName !== ''
    ) {
      addBlockchain(blockchainName, blockchainDisplayName, tokenID)
    }
  }, [save, blockchainName, blockchainDisplayName, tokenID])

  useEffect(() => {
    if (tokenID !== '' && auth.currentUser !== null) {
      getBlockchainList(tokenID)
    }
  }, [tokenID])

  useEffect(() => {
    if (confirm && deleteID !== 0) {
      deleteBlockchain(deleteID, tokenID)
    }
  }, [confirm, deleteID, tokenID])

  useEffect(() => {
    document.title = 'Blockchain - Dashboard'
  }, [])

  useEffect(() => {
    if (notificationOnSuccess) {
      setTimeout(() => {
        setNotificationOnSuccess(false)
      }, 2000)
    }
  }, [notificationOnSuccess])

  return (
    <Layout>
      <Notification colour={alert.severity} message={alert.message}/>
      <Box
        sx={{
          width: '70%',
          padding: 2,
          flexGrow: 1,
          overflow: 'scroll',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <span
            style={{
              fontSize: '18px',
              textTransform: 'uppercase',
              fontWeight: 500,
              marginRight: '10px',
            }}
          >
            Blockchains
          </span>
          <AddButton
            onClick={() => handleClickBlockchain()}
            variant="outlined"
            size="small"
          >
            Add Blockchain
          </AddButton>
        </Box>
        <div
          style={{
            height: '95%',
            width: '100%',
            marginTop: '20px',
          }}
        >
          <DataGrid
            rowHeight={35}
            rows={data}
            columns={columns}
            pageSize={50}
            rowsPerPageOptions={[50]}
            onSelectionModelChange={newSelectionModel => {
              setSelectionModel(newSelectionModel)
            }}
            processRowUpdate={processRowUpdate}
            selectionModel={selectionModel}
            disableMultipleSelection={true}
            experimentalFeatures={{ newEditingApi: true }}
            disableColumnMenu={true}
          />
        </div>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Delete Blockchain?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete{' '}
            <span style={{ fontWeight: 700 }}>{deleteName}</span> blockchain?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleConfirmation}>Delete</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openBlockchainForm} onClose={handleCloseBlockchain}>
        <DialogTitle>Blockchain</DialogTitle>
        <DialogContent>
          <DialogContentText>Create new blockchain</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth
            variant="standard"
            onChange={e => setBlockchainName(e.target.value)}
          />
          <TextField
            margin="dense"
            id="name"
            label="Display Name"
            type="text"
            fullWidth
            variant="standard"
            onChange={e => setBlockchainDisplayName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseBlockchain}>Cancel</Button>
          <Button onClick={() => handleAddBlockchain()}>Save</Button>
        </DialogActions>
      </Dialog>
    </Layout>
  )
}
