import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import { Routes, Route } from 'react-router-dom'
import Login from './pages/Login'
import Dashboard from './pages/Dashboard'
import News from './pages/News'
import Error404 from './pages/Error404'
import ContentTags from './pages/news/ContentTags'
import TwitterConfig from './pages/news/TwitterConfig'
import YoutubeConfig from './pages/news/YoutubeConfig'
import PrivateRoute from './components/PrivateRoute'
import Etf from './pages/assets-info/Etf'
import TokenInfo from './pages/assets-info/TokenInfo'
import Health from './pages/Health'
import Blockchain from './pages/assets-info/Blockchain'
import TokenAddress from './pages/assets-info/TokenAddress'
import User from './pages/user/User'

function App() {
  return (
    <div style={{ width: '100vw', height: '100vh' }}>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        {/* <Route path="/dashboard/news" element={<News />} /> */}
        <Route
          path="/dashboard/content-tags"
          element={
            <PrivateRoute>
              <ContentTags />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/twitter-config"
          element={
            <PrivateRoute>
              <TwitterConfig />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/youtube-config"
          element={
            <PrivateRoute>
              <YoutubeConfig />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/etf"
          element={
            <PrivateRoute>
              <Etf />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/token-info"
          element={
            <PrivateRoute>
              <TokenInfo />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/blockchain"
          element={
            <PrivateRoute>
              <Blockchain />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/token-address"
          element={
            <PrivateRoute>
              <TokenAddress />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/user"
          element={
            <PrivateRoute>
              <User />
            </PrivateRoute>
          }
        />
        <Route path="/health" element={<Health />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </div>
  )
}

export default App
