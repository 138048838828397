import { useEffect, useState } from 'react'
import { GetTokenInfoList, UpdateTokenInfo } from '../service/api'
import useTokenID from './use-token-id'

function useTokenInfo() {
  const tokenID = useTokenID()
  const [tokenLists, setTokenLists] = useState([])
  const [updateSuccess, setUpdateSuccess] = useState(false)

  const getTokenList = async val => {
    const response = await GetTokenInfoList(val)
    if (response.status === 200) setTokenLists(response.data.data)
  }

  async function update(newData) {
    try {
      const { data } = await UpdateTokenInfo(newData, tokenID)
      if (data.status === 1) {
        setUpdateSuccess(true)
        getTokenList(tokenID)
      }
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    if (tokenID) {
      getTokenList(tokenID)
    }
  }, [tokenID])

  return { tokenLists, update, updateSuccess }
}

export default useTokenInfo
