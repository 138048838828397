export const assetInfoLinks = [
  {
    displayName: 'Token',
    url: '/dashboard/token-info',
  },
  {
    displayName: 'Blockchain',
    url: '/dashboard/blockchain',
  },
  {
    displayName: 'Token Address',
    url: '/dashboard/token-address',
  },
  {
    displayName: 'Etf',
    url: '/dashboard/etf',
  },
]
export const newsLinks = [
  {
    displayName: 'Content Tags',
    url: '/dashboard/content-tags',
  },
  {
    displayName: 'Twitter Config',
    url: '/dashboard/twitter-config',
  },
  {
    displayName: 'Youtube Config',
    url: '/dashboard/youtube-config',
  },
]
export const userLinks = [
  {
    displayName: 'User',
    url: '/dashboard/user',
  },
]
export const socialMediaLinks = [
  {
    displayName: 'Twitter',
    url: 'https://twitter.com/defi_dive',
  },
  {
    displayName: 'Linkedin',
    url: 'https://www.linkedin.com/company/defidive',
  },
  {
    displayName: 'Discord',
    url: 'https://discord.gg/NrKmnHwuKp',
  },
  {
    displayName: 'Instagram',
    url: 'https://www.instagram.com/defidive/',
  },
  {
    displayName: 'Youtube',
    url: 'https://www.youtube.com/channel/UCh6BCPpQQhSjFbNHipIzCkQ',
  },
  {
    displayName: 'Tiktok',
    url: 'https://www.tiktok.com/@defidive',
  },
]

export const links = [
  {
    displayName: 'Defidive Dev Site',
    url: 'https://dev.defidive.com/',
  },
  {
    displayName: 'Defidive $1000 Portfolio',
    url: 'https://defidive.com/address/0x13df1417F71414BDBF1b6f2859D4Be087c7b744f/token',
  },
  {
    displayName: 'JIRA Dev Board',
    url: 'https://defidive.atlassian.net/jira/software/c/projects/DDD/boards/6',
  },
  {
    displayName: 'JIRA Marketing Board',
    url: 'https://defidive.atlassian.net/jira/core/projects/MAR/board',
  },
  {
    displayName: 'Confluence',
    url: 'https://defidive.atlassian.net/wiki/spaces/WIKI/overview',
  },
  {
    displayName: 'Miro Board',
    url: 'https://miro.com/welcomeonboard/WG5jU2F1bFJ6eXJHWEpFeDJ4NWhwTzZpNW0ycnZYcXFWREZRZHl5cU1md1RkcTRVVFduNjJHbWtMcjduUVl1YXwzNDU4NzY0NTc1MjI2ODQzOTU2fDI=?share_link_id=741131479827',
  },
  {
    displayName: 'Figma Board',
    url: 'https://www.figma.com/file/0WOaqMUBXlTI8QPiMKXXkC/defidive-draft?type=design&node-id=0%3A1&mode=design&t=ZtDbst10jWpGWBDE-1',
  },
  {
    displayName: 'CMS Strapi',
    url: 'https://cms.defidive.com/admin',
  },
  {
    displayName: 'Everytimezone',
    url: 'https://everytimezone.com/',
  },
  {
    displayName: 'Calendly',
    url: 'https://calendly.com/',
  },
  {
    displayName: 'Google Drive',
    url: 'https://drive.google.com/',
  },
  {
    displayName: 'Gmail',
    url: 'https://mail.google.com/',
  },
]
