import React, { useState, useEffect } from 'react'
import { auth } from '../../firebase'
import {
  AddYoutubeConfigs,
  DeleteYoutubeConfigs,
  GetYoutubeConfigs,
  UpdateYoutubeConfigs,
} from '../../service/api'
import Layout from '../../components/Layout'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { DataGrid } from '@mui/x-data-grid'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { styled } from '@mui/material/styles'
import useStore from '../../store'
import Notification from '../../components/Notification'
import useTokenID from '../../hooks/use-token-id'

const AddButton = styled(Button)(() => ({
  width: 20,
  color: '#15C0FC',
  '&.MuiButtonBase-root': {
    fontSize: '12px',
    padding: '2px 15px',
    border: '1px solid #15C0FC',
    minWidth: 'max-content',
    textTransform: 'none',
  },
}))
const DeleteButton = styled(Button)(() => ({
  width: 20,
  color: 'red',
  '&.MuiButtonBase-root': {
    fontSize: '12px',
    padding: '2px 15px',
    border: '1px solid red',
    minWidth: 'max-content',
    textTransform: 'none',
  },
}))

export default function YoutubeConfigs() {
  const tokenID = useTokenID()
  const notificationOnSuccess = useStore(state => state.notificationState)
  const setNotificationOnSuccess = useStore(state => state.handleNotification)
  const [alert, setAlert] = useState({ severity: 'success', message: 'hello' })
  const [selectionModel, setSelectionModel] = useState([])
  const [data, setData] = useState([])
  const [open, setOpen] = useState(false)
  const [openConfigForm, setOpenConfigForm] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [deleteID, setDeleteID] = useState(0)
  const [ytChannelName, setYtChannelName] = useState('')
  const [ytChannelID, setYtChannelID] = useState('')
  const [save, setSave] = useState(false)
  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    {
      field: 'youtube_channel_name',
      headerName: 'Youtube Channel Name',
      width: 400,
      editable: true,
    },
    {
      field: 'youtube_channel_id',
      headerName: 'Youtube Channel ID',
      width: 300,
      editable: true,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 130,
      sortable: false,
      renderCell: params => {
        return (
          <DeleteButton
            onClick={() => handleClickOpen(params)}
            variant="outlined"
            size="small"
          >
            Delete
          </DeleteButton>
        )
      },
    },
  ]
  const handleClickYoutubeConfig = () => {
    setOpenConfigForm(true)
  }
  const handleCloseYoutubeConfig = () => {
    setOpenConfigForm(false)
  }
  const handleClickOpen = params => {
    setOpen(true)
    setDeleteID(params.id)
  }

  const handleClose = () => {
    setOpen(false)
  }
  const handleConfirmation = () => {
    setConfirm(true)
    setOpen(false)
  }
  const handleAddYoutubeConfig = () => {
    setSave(true)
  }
  const processRowUpdate = newRow => {
    const updatedRow = { ...newRow, isNew: false }
    updateYoutubeConfig(newRow, tokenID)
    return updatedRow
  }

  const getYoutubeConfigs = async tokenID => {
    const { data } = await GetYoutubeConfigs(tokenID)
    if (data) {
      setData(data.data)
    } else {
      throw new Error('Problem fetching data')
    }
  }

  const addYoutubeConfig = async (ytChannelName, ytChannelID, tokenID) => {
    const { data } = await AddYoutubeConfigs(
      ytChannelName,
      ytChannelID,
      tokenID,
    )
    if (data.status === 1) {
      setSave(false)
      setOpenConfigForm(false)
      getYoutubeConfigs(tokenID)
      setAlert({ severity: 'success', message: 'Youtube Config added successfully' })
      setNotificationOnSuccess(true)
    }
    else{
      setSave(false)
      setOpenConfigForm(false)
      getYoutubeConfigs(tokenID)
      setAlert({ severity: 'error', message: 'Youtube Config fail to add' })
      setNotificationOnSuccess(true)
    }
  }

  const updateYoutubeConfig = async (newConfig, tokenID) => {
    const { data } = await UpdateYoutubeConfigs(newConfig, tokenID)
    if (data.status === 1) {
      getYoutubeConfigs(tokenID)
      setAlert({ severity: 'success', message: 'Youtube Config updated successfully' })
      setNotificationOnSuccess(true)
    }
    else {
      getYoutubeConfigs(tokenID)
      setAlert({ severity: 'error', message: 'Youtube Config fail to update' })
      setNotificationOnSuccess(true)
    }
  }

  const deleteYoutubeConfig = async (id, tokenID) => {
    const { data } = await DeleteYoutubeConfigs(id, tokenID)
    if (data.status === 1) {
      setConfirm(false)
      getYoutubeConfigs(tokenID)
      setAlert({ severity: 'success', message: 'Youtube Config deleted successfully' })
      setNotificationOnSuccess(true)
    }
    else {
      setConfirm(false)
      getYoutubeConfigs(tokenID)
      setAlert({ severity: 'error', message: 'Youtube Config fail to delete' })
      setNotificationOnSuccess(true)
    }
  }

  useEffect(() => {
    if (tokenID !== '' && save && ytChannelName !== '' && ytChannelID !== '') {
      addYoutubeConfig(ytChannelName, ytChannelID, tokenID)
    }
  }, [save, ytChannelName, ytChannelID, tokenID])

  useEffect(() => {
    if (tokenID !== '' && auth.currentUser !== null) {
      getYoutubeConfigs(tokenID)
    }
  }, [tokenID])

  useEffect(() => {
    if (confirm && deleteID !== 0) {
      deleteYoutubeConfig(deleteID, tokenID)
    }
  }, [confirm, deleteID, tokenID])

  useEffect(() => {
    document.title = 'Youtube Config - Dashboard'
  }, [])

  useEffect(() => {
    if (notificationOnSuccess) {
      setTimeout(() => {
        setNotificationOnSuccess(false)
      }, 2000)
    }
  }, [notificationOnSuccess])

  return (
    <Layout>
      <Notification colour={alert.severity} message={alert.message}/>
      <Box
        sx={{
          width: '70%',
          padding: 2,
          flexGrow: 1,
          overflow: 'scroll',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <span
            style={{
              fontSize: '18px',
              textTransform: 'uppercase',
              fontWeight: 500,
              marginRight: '10px',
            }}
          >
            Youtube Config
          </span>
          <AddButton
            onClick={() => handleClickYoutubeConfig()}
            variant="outlined"
            size="small"
          >
            Add youtube config
          </AddButton>
        </Box>
        <div style={{ height: '95%', width: '100%', marginTop: '20px' }}>
          <DataGrid
            rowHeight={35}
            rows={data}
            columns={columns}
            pageSize={50}
            rowsPerPageOptions={[50]}
            onSelectionModelChange={newSelectionModel => {
              setSelectionModel(newSelectionModel)
            }}
            processRowUpdate={processRowUpdate}
            selectionModel={selectionModel}
            disableMultipleSelection={true}
            experimentalFeatures={{ newEditingApi: true }}
            disableColumnMenu={true}
          />
        </div>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Delete Youtube Config?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this Youtube Config?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleConfirmation} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openConfigForm} onClose={handleCloseYoutubeConfig}>
        <DialogTitle>Youtube Config</DialogTitle>
        <DialogContent>
          <DialogContentText>Create new youtube config</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Youtube Channel Name"
            type="text"
            fullWidth
            variant="standard"
            onChange={e => setYtChannelName(e.target.value)}
          />
          <TextField
            margin="dense"
            id="name"
            label="Youtube Channel ID"
            type="text"
            fullWidth
            variant="standard"
            onChange={e => setYtChannelID(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseYoutubeConfig}>Cancel</Button>
          <Button onClick={() => handleAddYoutubeConfig()}>Save</Button>
        </DialogActions>
      </Dialog>
    </Layout>
  )
}
