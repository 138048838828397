import React from 'react'
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import useStore from '../store';

const Notification = ({colour, message}) => {
    const notificationOnSuccess = useStore((state) => state.notificationState)
    const setBears = useStore((state) => state.handleNotification)
    return (
    <div style={{position: 'absolute', top:0, left:'50%', translate:'-50% 0'}}>
        <Box sx={{ width: '100%' }}>
      <Collapse in={notificationOnSuccess}>
        <Alert variant="outlined" severity={colour}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setBears(!notificationOnSuccess)
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {message}
        </Alert>
      </Collapse>
    </Box>
    </div>
  )
}

export default Notification