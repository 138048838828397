import { useEffect, useState } from 'react'
import { auth } from '../firebase'

function useTokenID() {
  const [tokenID, setTokenID] = useState('')

  const getTokenID = async () => {
    const response = await auth.currentUser.getIdToken()
    const token = `Bearer ${response}`
    setTokenID(token)
  }
  useEffect(() => {
    getTokenID()
  }, [])

  return tokenID
}

export default useTokenID
