import axios from 'axios'

const baseURL = `${process.env.REACT_APP_baseUrl}`.replace(/(.*)\/$/, '$1')

export const GetContentTags = tokenID => {
  return axios(`${baseURL}/content-tags/all`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: tokenID,
    },
  })
}
export const AddContentTags = (name, category, aliases, tokenID) => {
  return axios(`${baseURL}/content-tags/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: tokenID,
    },
    data: JSON.stringify({ name, category, aliases }),
  })
}
export const UpdateContentTags = (newTag, tokenID) => {
  return axios(`${baseURL}/content-tags/`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: tokenID,
    },
    data: JSON.stringify(newTag),
  })
}
export const DeleteContentTags = (tagID, tokenID) => {
  return axios(`${baseURL}/content-tags/${tagID}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: tokenID,
    },
  })
}

export const GetTwitterConfigs = tokenID => {
  return axios(`${baseURL}/twitter-configs/all`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: tokenID,
    },
  })
}
export const AddTwitterConfigs = (tweet_handle, priority_weight, tokenID) => {
  return axios(`${baseURL}/twitter-configs/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: tokenID,
    },
    data: JSON.stringify({ tweet_handle, priority_weight }),
  })
}
export const UpdateTwitterConfigs = (newConfig, tokenID) => {
  return axios(`${baseURL}/twitter-configs/`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: tokenID,
    },
    data: JSON.stringify(newConfig),
  })
}
export const DeleteTwitterConfigs = (configID, tokenID) => {
  return axios(`${baseURL}/twitter-configs/${configID}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: tokenID,
    },
  })
}

export const GetYoutubeConfigs = tokenID => {
  return axios(`${baseURL}/youtube-configs/all`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: tokenID,
    },
  })
}
export const AddYoutubeConfigs = (
  youtube_channel_name,
  youtube_channel_id,
  tokenID,
) => {
  return axios(`${baseURL}/youtube-configs/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: tokenID,
    },
    data: JSON.stringify({ youtube_channel_name, youtube_channel_id }),
  })
}
export const UpdateYoutubeConfigs = (newConfig, tokenID) => {
  return axios(`${baseURL}/youtube-configs/`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: tokenID,
    },
    data: JSON.stringify(newConfig),
  })
}
export const DeleteYoutubeConfigs = (configID, tokenID) => {
  return axios(`${baseURL}/youtube-configs/${configID}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: tokenID,
    },
  })
}

export const GetEtfList = tokenID => {
  return axios(`${baseURL}/etf/all`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: tokenID,
    },
  })
}
export const UpdateEtf = (newData, tokenID) => {
  return axios(`${baseURL}/etf/`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: tokenID,
    },
    data: JSON.stringify(newData),
  })
}

export const GetTokenInfoList = tokenID => {
  return axios(`${baseURL}/token-info/all`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: tokenID,
    },
  })
}
export const UpdateTokenInfo = (newData, tokenID) => {
  return axios(`${baseURL}/token-info/`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: tokenID,
    },
    data: JSON.stringify(newData),
  })
}
export const HealthCheck = () => {
  return axios(`${baseURL}/health`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })
}

export const GetBlockchainList = tokenID => {
  return axios(`${baseURL}/blockchain`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: tokenID,
    },
  })
}
export const AddBlockchain = (name, display_name, tokenID) => {
  return axios(`${baseURL}/blockchain/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: tokenID,
    },
    data: JSON.stringify({ name, display_name }),
  })
}
export const UpdateBlockchain = (newBlockchain, blockchainID, tokenID) => {
  return axios(`${baseURL}/blockchain/${blockchainID}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: tokenID,
    },
    data: JSON.stringify(newBlockchain, blockchainID),
  })
}
export const DeleteBlockchain = (blockchainID, tokenID) => {
  return axios(`${baseURL}/blockchain/${blockchainID}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: tokenID,
    },
  })
}

export const GetTokenAddress = tokenID => {
  return axios(`${baseURL}/token-address`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: tokenID,
    },
  })
}

export const AddTokenAddress = (
  token_name,
  token_symbol,
  token_address,
  blockchain_id,
  tokenID,
) => {
  return axios(`${baseURL}/token-address/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: tokenID,
    },
    data: JSON.stringify({
      token_name,
      token_symbol,
      token_address,
      blockchain_id,
    }),
  })
}

export const UpdateTokenAddress = (newTokenAddress, tokenID) => {
  return axios(`${baseURL}/token-address/${newTokenAddress.id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: tokenID,
    },
    data: JSON.stringify(newTokenAddress),
  })
}

export const DeleteTokenAddress = (tokenAddressID, tokenID) => {
  return axios(`${baseURL}/token-address/${tokenAddressID}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: tokenID,
    },
  })
}

export const GetUser = tokenID => {
  return axios(`${baseURL}/user/all`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: tokenID,
    },
  })
}

export const DeleteUser = (userID, tokenID) => {
  return axios(`${baseURL}/user/${userID}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: tokenID,
    },
  })
}
