import React, { useState, useEffect } from 'react'
import { auth } from '../../firebase'
import {
  GetTokenAddress,
  UpdateTokenAddress,
  AddTokenAddress,
  DeleteTokenAddress,
  GetBlockchainList,
} from '../../service/api'
import Layout from '../../components/Layout'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { DataGrid } from '@mui/x-data-grid'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Autocomplete from '@mui/material/Autocomplete'
import { styled } from '@mui/material/styles'
import useStore from '../../store'
import Notification from '../../components/Notification'
import useTokenID from '../../hooks/use-token-id'

const AddButton = styled(Button)(() => ({
  width: 20,
  color: '#15C0FC',
  '&.MuiButtonBase-root': {
    fontSize: '12px',
    padding: '2px 15px',
    border: '1px solid #15C0FC',
    minWidth: 'max-content',
    textTransform: 'none',
  },
}))
const DeleteButton = styled(Button)(() => ({
  width: 20,
  color: 'red',
  '&.MuiButtonBase-root': {
    fontSize: '12px',
    padding: '2px 15px',
    border: '1px solid red',
    minWidth: 'max-content',
    textTransform: 'none',
  },
}))

export default function Blockchain() {
  const tokenID = useTokenID()
  const notificationOnSuccess = useStore(state => state.notificationState)
  const setNotificationOnSuccess = useStore(state => state.handleNotification)
  const [alert, setAlert] = useState({ severity: 'success', message: 'hello' })
  const [selectionModel, setSelectionModel] = useState([])
  const [data, setData] = useState([])
  const [blockchainList, setBlockchainList] = useState([])
  const [open, setOpen] = useState(false)
  const [openTokenAddressForm, setOpenTokenAddressForm] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [deleteID, setDeleteID] = useState(0)
  const [tokenName, setTokenName] = useState('')
  const [tokenSymbol, setTokenSymbol] = useState('')
  const [tokenAddress, setTokenAddress] = useState('')
  const [blockchain, setBlockchain] = useState('')
  const [save, setSave] = useState(false)
  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    {
      field: 'token_name',
      headerName: 'Token Name',
      width: 150,
      editable: false,
    },
    {
      field: 'token_symbol',
      headerName: 'Token Symbol',
      width: 150,
      editable: false,
    },
    {
      field: 'blockchain_name',
      headerName: 'Blockchain Name',
      width: 200,
      editable: false,
    },
    {
      field: 'token_address',
      headerName: 'Token Address',
      width: 400,
      editable: true,
    },

    {
      field: 'actions',
      headerName: 'Actions',
      width: 130,
      sortable: false,
      renderCell: params => {
        return (
          <DeleteButton
            onClick={() => handleClickOpen(params)}
            variant="outlined"
            size="small"
          >
            Delete
          </DeleteButton>
        )
      },
    },
  ]
  const handleBlockchain = event => {
    setBlockchain(event.target.value)
  }
  const handleClickTokenAddress = () => {
    setOpenTokenAddressForm(true)
  }
  const handleCloseTokenAddress = () => {
    setOpenTokenAddressForm(false)
  }
  const handleClickOpen = params => {
    setOpen(true)
    setDeleteID(params.id)
  }

  const handleClose = () => {
    setOpen(false)
  }
  const handleConfirmation = () => {
    setConfirm(true)
    setOpen(false)
  }
  const handleAddTokenAddress = () => {
    setSave(true)
  }
  const processRowUpdate = newRow => {
    const updatedRow = { ...newRow, isNew: false }
    updateTokenAddress(newRow, tokenID)
    return updatedRow
  }

  const getTokenAddress = async tokenID => {
    const { data } = await GetTokenAddress(tokenID)

    if (data) {
      const renderData = []
      data.data.map(value => {
        const obj = {}
        obj.id = value.id
        obj.token_name = value.token_name
        obj.token_symbol = value.token_symbol
        obj.blockchain_name = value.blockchain_name
        obj.token_address = value.token_address
        renderData.push(obj)
      })
      setData(renderData)
    } else {
      throw new Error('Problem fetching data')
    }
  }

  const addTokenAddress = async (
    tokenName,
    tokenSymbol,
    tokenAddress,
    blockchainID,
    tokenID,
  ) => {
    const { data } = await AddTokenAddress(
      tokenName,
      tokenSymbol,
      tokenAddress,
      blockchainID,
      tokenID,
    )

    setBlockchain('')
    if (data.status === 1) {
      setSave(false)
      setOpenTokenAddressForm(false)
      getTokenAddress(tokenID)
      setAlert({ severity: 'success', message: 'Token Address added successfully' })
      setNotificationOnSuccess(true)
    }
    else{
      setSave(false)
      setOpenTokenAddressForm(false)
      getTokenAddress(tokenID)
      setAlert({ severity: 'error', message: 'Token Address fail to add' })
      setNotificationOnSuccess(true)
    }
  }

  const updateTokenAddress = async (newTokenAddress, tokenID) => {
    const { data } = await UpdateTokenAddress(newTokenAddress, tokenID)
    if (data.status === 1) {
      getTokenAddress(tokenID)
      setAlert({ severity: 'success', message: 'Token Address updated successfully' })
      setNotificationOnSuccess(true)
    }
    else {
      getTokenAddress(tokenID)
      setAlert({ severity: 'error', message: 'Token Address fail to update' })
      setNotificationOnSuccess(true)
    }
  }

  const deleteTokenAddress = async (id, tokenID) => {
    const { data } = await DeleteTokenAddress(id, tokenID)
    if (data.status === 1) {
      setConfirm(false)
      getTokenAddress(tokenID)
      setAlert({ severity: 'success', message: 'Token Address deleted successfully' })
      setNotificationOnSuccess(true)
    }
    else {
      setConfirm(false)
      getTokenAddress(tokenID)
      setAlert({ severity: 'error', message: 'Token Address fail to delete' })
      setNotificationOnSuccess(true)
    }
  }

  const getBlockchainList = async tokenID => {
    const { data } = await GetBlockchainList(tokenID)
    if (data) {
      const newData = data.data.filter(val => val.display_name !== null)
      setBlockchainList(newData)
    } else {
      throw new Error('Problem fetching data')
    }
  }

  useEffect(() => {
    if (
      tokenID !== '' &&
      save &&
      tokenName !== '' &&
      tokenSymbol !== '' &&
      tokenAddress !== '' &&
      blockchain.id !== ''
    ) {
      addTokenAddress(
        tokenName,
        tokenSymbol,
        tokenAddress,
        blockchain.id,
        tokenID,
      )
    }
  }, [save, tokenName, tokenSymbol, tokenAddress, blockchain, tokenID])


  useEffect(() => {
    if (tokenID !== '' && auth.currentUser !== null) {
      getTokenAddress(tokenID)
    }
  }, [tokenID])

  useEffect(() => {
    if (openTokenAddressForm && tokenID !== '' && auth.currentUser !== null) {
      getBlockchainList(tokenID)
    }
  }, [openTokenAddressForm, tokenID])

  useEffect(() => {
    if (confirm && deleteID !== 0) {
      deleteTokenAddress(deleteID, tokenID)
    }
  }, [confirm, deleteID, tokenID])

  useEffect(() => {
    document.title = 'Token Address - Dashboard'
  }, [])

  useEffect(() => {
    if (notificationOnSuccess) {
      setTimeout(() => {
        setNotificationOnSuccess(false)
      }, 2000)
    }
  }, [notificationOnSuccess])

  return (
    <Layout>
      <Notification colour={alert.severity} message={alert.message}/>
      <Box
        sx={{
          width: '70%',
          padding: 2,
          flexGrow: 1,
          overflow: 'scroll',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <span
            style={{
              fontSize: '18px',
              textTransform: 'uppercase',
              fontWeight: 500,
              marginRight: '10px',
            }}
          >
            Token Address
          </span>
          <AddButton
            onClick={() => handleClickTokenAddress()}
            variant="outlined"
            size="small"
          >
            Add token address
          </AddButton>
        </Box>
        <div
          style={{
            height: '95%',
            width: '100%',
            marginTop: '20px',
          }}
        >
          <DataGrid
            rowHeight={35}
            rows={data}
            columns={columns}
            pageSize={50}
            rowsPerPageOptions={[50]}
            onSelectionModelChange={newSelectionModel => {
              setSelectionModel(newSelectionModel)
            }}
            processRowUpdate={processRowUpdate}
            selectionModel={selectionModel}
            disableMultipleSelection={true}
            experimentalFeatures={{ newEditingApi: true }}
            disableColumnMenu={true}
          />
        </div>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Delete Token Address?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this token address?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleConfirmation}>Delete</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openTokenAddressForm} onClose={handleCloseTokenAddress}>
        <DialogTitle>Token Address</DialogTitle>
        <DialogContent>
          <DialogContentText>Create a new token address</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="token_name"
            label="Token Name"
            type="text"
            fullWidth
            variant="standard"
            onChange={e => setTokenName(e.target.value)}
          />
          <TextField
            margin="dense"
            id="token_symbol"
            label="Token Symbol"
            type="text"
            fullWidth
            variant="standard"
            onChange={e => setTokenSymbol(e.target.value)}
          />
          <TextField
            margin="dense"
            id="token_address"
            label="Token Address"
            type="text"
            fullWidth
            variant="standard"
            onChange={e => setTokenAddress(e.target.value)}
          />
          <Autocomplete
            id="blockchain"
            options={blockchainList}
            getOptionLabel={option => option.display_name || ''}
            value={blockchain.display_name}
            onChange={(event, newValue) => {
              setBlockchain(newValue)
            }}
            sx={{ width: '100%', marginTop: 2 }}
            renderInput={params => <TextField {...params} label="Blockchain" />}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseTokenAddress}>Cancel</Button>
          <Button onClick={() => handleAddTokenAddress()}>Save</Button>
        </DialogActions>
      </Dialog>
    </Layout>
  )
}
