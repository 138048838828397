import React, { useState, useEffect } from 'react'
import { auth } from '../../firebase'
import { GetEtfList, UpdateEtf } from '../../service/api'
import Layout from '../../components/Layout'
import Box from '@mui/material/Box'
import useTokenID from '../../hooks/use-token-id'
import Notification from '../../components/Notification'
import useStore from '../../store'
import { DataGrid } from '@mui/x-data-grid'
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
export default function Etf() {
  const tokenID = useTokenID()
  const notificationOnSuccess = useStore(state => state.notificationState)
  const setNotificationOnSuccess = useStore(state => state.handleNotification)
  const [type, setTypes] = useState({});
  const [alert, setAlert] = useState({ severity: 'success', message: 'hello' })
  const [selectionModel, setSelectionModel] = useState([])
  const [data, setData] = useState([])
  const columns = [
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'ticker', headerName: 'Ticker', width: 150},
    { field: 'name', headerName: 'Etf Name', width: 400, editable: true },
    { field: 'issuer', headerName: 'Issuer', width: 400, editable: true },
    { field: 'type', headerName: 'Type', width: 150, renderCell: params => {
      return (
        <div style={{ color: 'blue' }}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={type[params.id] || ''}
            label="Type"
            onChange={(event) => handleChange(event, params.row)}
          >
            <MenuItem value={"btc"}>BTC</MenuItem>
            <MenuItem value={"eth"}>ETH</MenuItem>
            <MenuItem value={"both"}>Both</MenuItem>
          </Select>
        </div>
      )
    } },
  ]

  const handleChange = (event, params) => {
    console.log(params, event.target.value)
    updateEtf({ id: params.id, ticker: params.ticker, name: params.name, issuer: params.issuer, type: event.target.value })
  };

  const processRowUpdate = newRow => {
    const updatedRow = { ...newRow, isNew: false }
    updateEtf(newRow)
    return updatedRow
  }

  const getEtfList = async () => {
    const { data } = await GetEtfList(tokenID)
    if (data) {
      setData(data)
      // Set initial types
      const initialTypes = {}
      data.forEach(etf => {
        initialTypes[etf.id] = etf.type
      })
      setTypes(initialTypes)
    } else {
      throw new Error('Error fetching etf list')
    }
  }

  const updateEtf = async newEtf => {
    const { data } = await UpdateEtf(newEtf, tokenID)
    if (data.status === 'success') {
      getEtfList()
      setAlert({ severity: 'success', message: 'Etf updated successfully' })
      setNotificationOnSuccess(true)
    } else {
      getEtfList()
      setAlert({ severity: 'error', message: 'Etf fail to update' })
      setNotificationOnSuccess(true)
    }
  }

  useEffect(() => {
    if (tokenID !== '' && auth.currentUser !== null) {
      getEtfList()
    }
  }, [tokenID])

  useEffect(() => {
    document.title = 'ETF - Dashboard'
  }, [])

  useEffect(() => {
    if (notificationOnSuccess) {
      setTimeout(() => {
        setNotificationOnSuccess(false)
      }, 2000)
    }
  }, [notificationOnSuccess, setNotificationOnSuccess])

  return (
    <Layout>
      <Notification colour={alert.severity} message={alert.message} />
      <Box
        sx={{
          width: '70%',
          padding: 2,
          flexGrow: 1,
          overflow: 'scroll',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <span
            style={{
              fontSize: '18px',
              textTransform: 'uppercase',
              fontWeight: 500,
              marginRight: '10px',
            }}
          >
            Youtube Config
          </span>
        </Box>
        <div style={{ height: '95%', width: '100%', marginTop: '20px' }}>
          <DataGrid
            rowHeight={35}
            rows={data}
            columns={columns}
            pageSize={50}
            rowsPerPageOptions={[50]}
            onSelectionModelChange={newSelectionModel => {
              setSelectionModel(newSelectionModel)
            }}
            processRowUpdate={processRowUpdate}
            selectionModel={selectionModel}
            disableMultipleSelection={true}
            experimentalFeatures={{ newEditingApi: true }}
            disableColumnMenu={true}
          />
        </div>
      </Box>
    </Layout>
  )
}
