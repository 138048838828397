import Box from '@mui/material/Box'

function Complete() {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <p
        style={{
          display: 'flex',
          alignItems: 'center',
          fontSize: '12px',
          color: 'white',
          backgroundColor: '#4bb543',
          width: 'max-content',
          padding: '2px 6px 2px 4px',
          fontWeight: 'bold',
          borderRadius: '1000rem',
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={2}
          stroke="currentColor"
          style={{ width: 16, height: 16, marginRight: 2 }}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        Complete
      </p>
    </Box>
  )
}

export default Complete
