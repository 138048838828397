import React, { useState, useEffect } from 'react'
import Layout from '../../components/Layout'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TablePagination from '@mui/material/TablePagination'
import Paper from '@mui/material/Paper'
import Complete from '../../components/status/Complete'
import Incomplete from '../../components/status/Incomplete'

import OutlinedInput from '@mui/material/OutlinedInput'
import FormControl from '@mui/material/FormControl'
import ListItemText from '@mui/material/ListItemText'
import Checkbox from '@mui/material/Checkbox'
import useTokenInfo from '../../hooks/use-token-info'
import useTokenID from '../../hooks/use-token-id'
import Notification from '../../components/Notification'
import useStore from '../../store'
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: '20%',
    },
  },
}

const EditButton = styled(Button)(() => ({
  width: 20,
  color: '#15C0FC',
  '&.MuiButtonBase-root': {
    fontSize: '12px',
    padding: '2px 15px',
    border: '1px solid #15C0FC',
    minWidth: 'max-content',
    textTransform: 'none',
  },
}))

const consensus = [
  'Proof of Concept',
  'Proof of Work',
  'Proof of Stake',
  'Proof of History',
  'Proof of Burn',
  'Delegated Proof of Stake',
  'Practical Byzantine Fault Tolerance',
  'Tendermint',
  'Hybrid Consensus',
  'Proof of Space',
  'Proof of Elapsed Time',
]

const TokenInfo = () => {
  const tokenID = useTokenID()
  const notificationOnSuccess = useStore(state => state.notificationState)
  const setNotificationOnSuccess = useStore(state => state.handleNotification)
  const { tokenLists, update, updateSuccess } = useTokenInfo()
  const [alert, setAlert] = useState({ severity: 'success', message: 'hello' })
  const [openEditForm, setOpenEditForm] = useState(false)
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(50)
  const [formData, setFormData] = useState({
    id: '',
    token_name: '',
    website_url: '',
    block_explorer_url: '',
    consensus: [],
    summary: '',
  })
  const [filteredData, setFilteredData] = useState([])
  const [searchInput, setSearchInput] = useState('')
  const [selectedConsensus, setSelectedConsensus] = useState(formData.consensus)

  const handleSearch = event => {
    setSearchInput(event.target.value)
    const query = event.target.value
    var updatedList = [...tokenLists]
    updatedList = updatedList.filter(item => {
      return item.token_name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    })
    setFilteredData(updatedList)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 12))
    setPage(0)
  }

  const handleEdit = data => {
    setFormData(data)
    setOpenEditForm(true)
  }
  const handleCloseEdit = () => {
    setOpenEditForm(false)
  }

  const handleSave = () => {
    update(formData)
    if (updateSuccess) {
      setOpenEditForm(false)
      setAlert({ severity: 'success', message: 'Token info updated successfully' })
      setNotificationOnSuccess(true)
    }
    else{
      setAlert({ severity: 'error', message: 'Failed to update token info' })
      setNotificationOnSuccess(true)
    }
  }

  useEffect(() => {
    document.title = 'Token Info - Dashboard'
  }, [])

  const handleChange = event => {
    const {
      target: { value },
    } = event

    setSelectedConsensus(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    )

    setFormData({
      ...formData,
      consensus: value,
    })
  }

  useEffect(() => {
    if (formData.consensus.length !== 0) {
      const updated = formData.consensus.map(item => item.trim())
      setSelectedConsensus(updated)
    }
  }, [formData.consensus])

  useEffect(() => {
    if (notificationOnSuccess) {
      setTimeout(() => {
        setNotificationOnSuccess(false)
      }, 2000)
    }
  }, [notificationOnSuccess])
  return (
    <Layout>
      <Notification colour={alert.severity} message={alert.message}/>
      <Box
        sx={{
          width: '70%',
          padding: 2,
          flexGrow: 1,
          overflow: 'scroll',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <span
            style={{
              fontSize: '18px',
              textTransform: 'uppercase',
              fontWeight: 500,
              marginRight: '10px',
            }}
          >
            Token Info
          </span>
        </Box>
        <TextField
          id="search-token"
          label="Search token"
          variant="outlined"
          size="small"
          value={searchInput}
          onChange={handleSearch}
          sx={{ marginTop: 2, width: '30%' }}
        />
        <div
          style={{
            height: 650,
            width: '100%',
            marginTop: '20px',
          }}
        >
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Symbol</TableCell>
                  <TableCell>Token Name</TableCell>
                  <TableCell align="center">Website URL</TableCell>
                  <TableCell align="center">Block Explorer URL</TableCell>
                  <TableCell align="center">Consensus</TableCell>
                  <TableCell align="center">Summary</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {searchInput !== ''
                  ? filteredData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage,
                      )
                      ?.map(row => (
                        <TableRow
                          key={row.id}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                        >
                          <TableCell sx={{ paddingTop: 0, paddingBottom: 0 }}>
                            {row.id}
                          </TableCell>
                          <TableCell sx={{ paddingTop: 0, paddingBottom: 0 }}>
                            {row.symbol}
                          </TableCell>
                          <TableCell sx={{ paddingTop: 0, paddingBottom: 0 }}>
                            {row.token_name}
                          </TableCell>
                          <TableCell
                            sx={{ paddingTop: 0, paddingBottom: 0 }}
                            align="center"
                          >
                            {row.website_url ? <Complete /> : <Incomplete />}
                          </TableCell>
                          <TableCell
                            sx={{ paddingTop: 0, paddingBottom: 0 }}
                            align="center"
                          >
                            {row?.block_explorer_url ? (
                              <Complete />
                            ) : (
                              <Incomplete />
                            )}
                          </TableCell>
                          <TableCell
                            sx={{ paddingTop: 0, paddingBottom: 0 }}
                            align="center"
                          >
                            {row?.consensus.length > 0 ? (
                              <Complete />
                            ) : (
                              <Incomplete />
                            )}
                          </TableCell>
                          <TableCell
                            sx={{ paddingTop: 0, paddingBottom: 0 }}
                            align="center"
                          >
                            {row.summary ? <Complete /> : <Incomplete />}
                          </TableCell>
                          <TableCell
                            sx={{ paddingTop: 0, paddingBottom: 0 }}
                            align="center"
                          >
                            <EditButton
                              onClick={() => handleEdit(row)}
                              variant="outlined"
                              size="small"
                            >
                              Edit
                            </EditButton>
                          </TableCell>
                        </TableRow>
                      ))
                  : tokenLists
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage,
                      )
                      ?.map(row => (
                        <TableRow
                          key={row.id}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                        >
                          <TableCell sx={{ paddingTop: 0, paddingBottom: 0 }}>
                            {row.id}
                          </TableCell>
                          <TableCell sx={{ paddingTop: 0, paddingBottom: 0 }}>
                            {row.symbol}
                          </TableCell>
                          <TableCell sx={{ paddingTop: 0, paddingBottom: 0 }}>
                            {row.token_name}
                          </TableCell>
                          <TableCell
                            sx={{ paddingTop: 0, paddingBottom: 0 }}
                            align="center"
                          >
                            {row.website_url ? <Complete /> : <Incomplete />}
                          </TableCell>
                          <TableCell
                            sx={{ paddingTop: 0, paddingBottom: 0 }}
                            align="center"
                          >
                            {row?.block_explorer_url ? (
                              <Complete />
                            ) : (
                              <Incomplete />
                            )}
                          </TableCell>
                          <TableCell
                            sx={{ paddingTop: 0, paddingBottom: 0 }}
                            align="center"
                          >
                            {row?.consensus.length > 0 ? (
                              <Complete />
                            ) : (
                              <Incomplete />
                            )}
                          </TableCell>
                          <TableCell
                            sx={{ paddingTop: 0, paddingBottom: 0 }}
                            align="center"
                          >
                            {row.summary ? <Complete /> : <Incomplete />}
                          </TableCell>
                          <TableCell
                            sx={{ paddingTop: 0, paddingBottom: 0 }}
                            align="center"
                          >
                            <EditButton
                              onClick={() => handleEdit(row)}
                              variant="outlined"
                              size="small"
                            >
                              Edit
                            </EditButton>
                          </TableCell>
                        </TableRow>
                      ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[50, 100]}
            component="div"
            count={tokenLists.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </Box>
      <Dialog open={openEditForm} onClose={handleCloseEdit}>
        <DialogTitle>Edit Token Info</DialogTitle>
        <DialogContent>
          <TextField
            value={formData.id}
            margin="dense"
            id="id"
            label="Token ID"
            type="text"
            fullWidth
            variant="standard"
            disabled
          />
          <TextField
            value={formData.token_name}
            margin="dense"
            id="tokenName"
            label="Token Name"
            type="text"
            fullWidth
            variant="standard"
            onChange={e =>
              setFormData({ ...formData, token_name: e.target.value })
            }
          />
          <TextField
            value={formData.website_url}
            margin="dense"
            id="websiteUrl"
            label="Website Url"
            type="text"
            fullWidth
            variant="standard"
            onChange={e =>
              setFormData({ ...formData, website_url: e.target.value })
            }
          />
          <TextField
            value={formData.block_explorer_url}
            margin="dense"
            id="blockExplorerUrl"
            label="Block Explorer Url"
            type="text"
            fullWidth
            variant="standard"
            onChange={e =>
              setFormData({ ...formData, block_explorer_url: e.target.value })
            }
          />

          {/* <InputLabel id="consensus">Consensus</InputLabel>
            <Box sx={{ display: 'flex', gap: 1, marginTop: 1 }}>
              {selectedConsensus.map(item => (
                <span
                  style={{
                    borderRadius: 200,
                    backgroundColor: 'green',
                    color: 'white',
                    padding: 6,
                  }}
                >
                  {item}
                </span>
              ))}
            </Box> */}
          <Box>
            <FormControl sx={{ marginY: 2, width: '100%' }}>
              <InputLabel id="consensus">Consensus</InputLabel>
              <Select
                labelId="consensus"
                id="consensusOption"
                multiple
                value={selectedConsensus}
                onChange={handleChange}
                input={<OutlinedInput label="Consensus" />}
                renderValue={selected => selected.join(',')}
                MenuProps={MenuProps}
              >
                {consensus.map(value => (
                  <MenuItem key={value} value={value}>
                    <Checkbox checked={selectedConsensus.indexOf(value) > -1} />
                    <ListItemText primary={value} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <TextField
            value={formData.summary}
            margin="dense"
            id="summary"
            label="Summary"
            type="text"
            fullWidth
            variant="standard"
            multiline
            onChange={e =>
              setFormData({ ...formData, summary: e.target.value })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEdit}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </Layout>
  )
}

export default TokenInfo
