import React, { useState, useEffect } from 'react'
import { auth } from '../../firebase'
import {
  AddTwitterConfigs,
  DeleteTwitterConfigs,
  GetTwitterConfigs,
  UpdateTwitterConfigs,
} from '../../service/api'
import Layout from '../../components/Layout'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { DataGrid } from '@mui/x-data-grid'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { styled } from '@mui/material/styles'
import Notification from '../../components/Notification'
import useStore from '../../store'

const AddButton = styled(Button)(() => ({
  width: 20,
  color: '#15C0FC',
  '&.MuiButtonBase-root': {
    fontSize: '12px',
    padding: '2px 15px',
    border: '1px solid #15C0FC',
    minWidth: 'max-content',
    textTransform: 'none',
  },
}))
const DeleteButton = styled(Button)(() => ({
  width: 20,
  color: 'red',
  '&.MuiButtonBase-root': {
    fontSize: '12px',
    padding: '2px 15px',
    border: '1px solid red',
    minWidth: 'max-content',
    textTransform: 'none',
  },
}))

export default function TwitterConfigs() {
  const notificationOnSuccess = useStore((state) => state.notificationState)
  const setNotificationOnSuccess = useStore((state) => state.handleNotification)
  const [selectionModel, setSelectionModel] = useState([])
  const [data, setData] = useState([])
  const [tokenID, setTokenID] = useState('')
  const [open, setOpen] = useState(false)
  const [openConfigForm, setOpenConfigForm] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [deleteID, setDeleteID] = useState(0)
  const [tweetHandle, setTweetHandle] = useState('')
  const [priorityWeight, setPriorityWeight] = useState('')
  const [save, setSave] = useState(false)
  const [colour, setColour] = useState('success')
  const [message, setMessage] = useState('successfully added twitter config')
  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    {
      field: 'tweet_handle',
      headerName: 'Tweet Handle',
      width: 400,
      editable: true,
    },
    {
      field: 'priority_weight',
      headerName: 'Priority Weight',
      width: 400,
      editable: true,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 130,
      sortable: false,
      renderCell: params => {
        return (
          <DeleteButton
            onClick={() => handleClickOpen(params)}
            variant="outlined"
            size="small"
          >
            Delete
          </DeleteButton>
        )
      },
    },
  ]

  const handleNotification = (notification) => {
    setNotificationOnSuccess(notification)
  }

  const handleClickTwitterConfig = () => {
    setOpenConfigForm(true)
  }
  const handleCloseTwitterConfig = () => {
    setOpenConfigForm(false)
  }
  const handleClickOpen = params => {
    setOpen(true)
    setDeleteID(params.id)
  }

  const handleClose = () => {
    setOpen(false)
  }
  const handleConfirmation = () => {
    setConfirm(true)
    setOpen(false)
  }
  const handleAddTwitterConfig = () => {
    setSave(true)
  }
  const processRowUpdate = newRow => {
    const updatedRow = { ...newRow, isNew: false }
    UpdateTwitterConfig(newRow, tokenID)
    return updatedRow
  }

  const getToken = async () => {
    const response = await auth.currentUser.getIdToken()
    setTokenID(`Bearer ${response}`)
  }

  const getTwitterConfigs = async tokenID => {
    const { data } = await GetTwitterConfigs(tokenID)
    if (data) {
      setData(data)
    } else {
      throw new Error('Problem fetching data')
    }
  }

  const addTwitterConfig = async (tweetHandle, priorityWeight, tokenID) => {
    const { data } = await AddTwitterConfigs(
      tweetHandle,
      priorityWeight,
      tokenID,
    )
    if (data.status === 'success') {
      setSave(false)
      setOpenConfigForm(false)
      getTwitterConfigs(tokenID)
      setNotificationOnSuccess(!notificationOnSuccess)
    }
  }

  const UpdateTwitterConfig = async (newConfig, tokenID) => {
    const { data } = await UpdateTwitterConfigs(newConfig, tokenID)
  }

  const deleteTwitterConfig = async (id, tokenID) => {
    const { data } = await DeleteTwitterConfigs(id, tokenID)
    if (data.status === 'success') {
      setConfirm(false)
      getTwitterConfigs(tokenID)
    }
  }

  useEffect(() => {
    if (tokenID !== '' && save && tweetHandle !== '' && priorityWeight !== '') {
      addTwitterConfig(tweetHandle, priorityWeight, tokenID)
    }
  }, [save, tweetHandle, priorityWeight, tokenID])

  useEffect(() => {
    getToken()
  }, [])

  useEffect(() => {
    if (tokenID !== '' && auth.currentUser !== null) {
      getTwitterConfigs(tokenID)
    }
  }, [tokenID])

  useEffect(() => {
    if (confirm && deleteID !== 0) {
      deleteTwitterConfig(deleteID, tokenID)
    }
  }, [confirm, deleteID, tokenID])

  useEffect(() => {
    document.title = 'Twitter Config - Dashboard'
  }, [])

  return (
    <Layout>
      <Notification colour={colour} message={message}/>
      <Box
        sx={{
          width: '70%',
          padding: 2,
          flexGrow: 1,
          overflow: 'scroll',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <span
            style={{
              fontSize: '18px',
              textTransform: 'uppercase',
              fontWeight: 500,
              marginRight: '10px',
            }}
          >
            Twitter Configs
          </span>
          <AddButton
            onClick={() => handleClickTwitterConfig()}
            variant="outlined"
            size="small"
          >
            Add twitter config
          </AddButton>
        </Box>
        <div style={{ height: '95%', width: '100%', marginTop: '20px' }}>
          <DataGrid
            rowHeight={35}
            rows={data}
            columns={columns}
            pageSize={50}
            rowsPerPageOptions={[50]}
            onSelectionModelChange={newSelectionModel => {
              setSelectionModel(newSelectionModel)
            }}
            processRowUpdate={processRowUpdate}
            selectionModel={selectionModel}
            disableMultipleSelection={true}
            experimentalFeatures={{ newEditingApi: true }}
            disableColumnMenu={true}
          />
        </div>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Delete Twitter Config?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this Twitter Config?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleConfirmation} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openConfigForm} onClose={handleCloseTwitterConfig}>
        <DialogTitle>Twitter Config</DialogTitle>
        <DialogContent>
          <DialogContentText>Create new twitter config</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Tweet Handle"
            type="text"
            fullWidth
            variant="standard"
            onChange={e => setTweetHandle(e.target.value)}
          />
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Priority Weight"
            type="text"
            fullWidth
            variant="standard"
            onChange={e => setPriorityWeight(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseTwitterConfig}>Cancel</Button>
          <Button onClick={() => handleAddTwitterConfig()}>Save</Button>
        </DialogActions>
      </Dialog>
    </Layout>
  )
}
