import { Container } from '@mui/material'
import React, { useEffect } from 'react'
import Layout from '../components/Layout'

function Dashboard() {
  useEffect(() => {
    document.title = 'Dashboard'
  }, [])
  return (
    <Layout>
      <Container sx={{ marginTop: 4 }}>Dashboard</Container>
    </Layout>
  )
}

export default Dashboard
