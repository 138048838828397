import React, { useEffect } from 'react'
import { getAuth, signInWithPopup } from 'firebase/auth'
import { useNavigate } from 'react-router-dom'
import { setDoc, doc } from 'firebase/firestore'
import { auth, db, provider } from '../firebase'
import Box from '@mui/material/Box'
import { Button } from '@mui/material'
import googleIcon from '../assets/icons/google_icon.svg'

function Login() {
  const navigate = useNavigate()

  const validateAccount = async () => {
    let user = getAuth().currentUser

    try {
      await setDoc(
        doc(db, 'users', user.uid),
        {
          displayName: user.displayName,
          email: user.email,
          photoURL: user.photoURL,
        },
        {
          merge: true,
        },
      )
      return true
    } catch (err) {
      // Not a white-listed domain
      console.log(err)
      return false
    }
  }

  const signIn = () => {
    signInWithPopup(auth, provider)
      .then(result => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        // const credential = GoogleAuthProvider.credentialFromResult(result)
        // const token = credential.accessToken
        // The signed-in user info.
        // const user = result.user

        validateAccount().then(validAccount => {
          if (validAccount) {
            navigate('/dashboard')
          } else {
            auth.signOut()
          }
        })
      })
      .catch(alert)
  }
  useEffect(() => {
    document.title = 'Defidive Backoffice'
  })

  return (
    <Box
      component="div"
      sx={{
        backgroundColor: '#f2f2f2',
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        component="div"
        sx={{
          backgroundColor: 'white',
          width: 400,
          height: 500,
          boxShadow: '0px 0px 30px #e6e6e6',
          borderRadius: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            backgroundColor: '#191C2E',
            borderRadius: 1,
            padding: '6px 2px 2px 2px',
          }}
        >
          <img
            style={{
              width: 200,
              height: 50,
            }}
            alt="logo"
            src="https://defidive.com/_next/static/media/DefiDive-Logo-Colour-Dark-RGB.2764fc23.svg"
          />
        </Box>
        <span style={{ fontSize: 16, marginBottom: 50, marginTop: 30 }}>
          Backoffice
        </span>
        <Button
          onClick={signIn}
          sx={{
            textTransform: 'capitalize',
            color: 'black',
            border: '1px solid #f2f2f2',
            fontWeight: 400,
            fontSize: '12px',
          }}
        >
          <img
            style={{ width: 16, height: 16, marginRight: 10 }}
            alt="google-icon"
            src={googleIcon}
          />
          Continue with Google
        </Button>
      </Box>
    </Box>
  )
}

export default Login
