import { Container } from '@mui/material'
import React, { useState, useEffect } from 'react'
import Layout from '../components/Layout'
import { HealthCheck } from '../service/api'

function Health() {
  const [healthCheck, setHealthCheck] = useState(false)
  const checkHealth = async () => {
    const response = await HealthCheck()
    if (response?.data) {
      setHealthCheck(true)
    } else {
      setHealthCheck(false)
    }
  }
  useEffect(() => {
    checkHealth()
  }, [])
  return (
    <Container sx={{ marginTop: 4 }}>
      Health {healthCheck ? <span>OK</span> : <span>NOT OK</span>}
    </Container>
  )
}

export default Health
