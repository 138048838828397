import Box from '@mui/material/Box'

function Label(props) {
  const { labelText, icon } = props
  return (
    <Box
      sx={{
        fontSize: '12px',
        display: 'flex',
        alignItems: 'center',
        color: '#5e5e5e',
        fontWeight: 500,
        padding: '5px',
        margin: '0 10px',
      }}
    >
      {icon}
      {labelText}
    </Box>
  )
}

export default Label
