import { auth } from '../firebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useNavigate } from 'react-router-dom'

function PrivateRoute({ children }) {
  const [user] = useAuthState(auth)
  const navigate = useNavigate()

  return user ? children : navigate('/')
}

export default PrivateRoute
