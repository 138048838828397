import Box from '@mui/material/Box'
import React from 'react'
import Sidebar from './sidebar/Sidebar'

function Layout({ children }) {
  return (
    <Box
      component="div"
      sx={{ display: 'flex', width: '100%', height: '100%', position: 'relative' }}
    >
      <Sidebar />
      {children}
    </Box>
  )
}

export default Layout
