import Box from '@mui/material/Box'

function Incomplete() {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <p
        style={{
          display: 'flex',
          alignItems: 'center',
          fontSize: '12px',
          color: 'white',
          backgroundColor: '#fc100d',
          width: 'max-content',
          padding: '2px 6px 2px 4px',
          fontWeight: 'bold',
          borderRadius: '1000rem',
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={2}
          stroke="currentColor"
          style={{ width: 16, height: 16, marginRight: 2 }}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        Incomplete
      </p>
    </Box>
  )
}

export default Incomplete
